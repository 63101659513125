import React,{lazy, Suspense,useEffect,useState} from 'react';
import {  BrowserRouter as Router,  Switch,Route} from "react-router-dom";
// import { Offline, Online ,Detector} from "react-detect-offline";
import { Provider } from 'react-redux';
import ReactPWAInstallProvider from "react-pwa-install";
import store from './store';
import './App.css';
import './index.css'
import './Call.css';
import './ResponsiveApp.css';
import './Stylesheets/Admin.css';
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';




// import Connect from './Components/Connect';
const Connect = lazy(()=> import('./Components/Connect'));
// const Connect2 = lazy(() => import('./Components/Connect'));
// import Calling_window from './Components/Call_window/Calling_window';
// import Calling_meeting from './Components/Call_window/Calling_meeting';
const Calling_meeting = lazy(()=> import('./Components/Call_window/Calling_meeting'));
// import CalendarComponent from './Components/Calendar/Test.js';
// import CovidPage from './Components/Covid/CovidPage';
// import Register from './Components/Register';
const Register = lazy(()=> import('./Components/Register'));
// import Login from './Components/Login';
const Login = lazy(()=> import('./Components/Login'));

// import Files from './Components/Files';
// import Logout from './Components/Logout';
const Logout = lazy(()=> import('./Components/Logout'));

// import ForgotPassword from './Components/ForgotPassword';
const ForgotPassword = lazy(()=> import('./Components/ForgotPassword'));

// import InvitedMember from './Components/InvitedMember';
const InvitedMember = lazy(()=> import('./Components/InvitedMember'));

// import Company from './Components/Company';
const Company = lazy(()=> import('./Components/Company'));
const AdminPanel = lazy(()=> import('./Components/Admin/AdminPanel'))
// import NotFound from './Components/NotFound';
const NotFound = lazy(()=> import('./Components/NotFound'))
// import ErrorPage from './Components/Error';
const ErrorPage = lazy(()=> import('./Components/Error'))

// import CovidHome from './Components/COVID19/CovidHome';
// import CovidPublic from './Components/COVID19/CovidPublic';
// import Mobile from './Components/Mobile/Home';
// import ChatScreen from './Components/Mobile/ChatScreen';
// import signUp from './Components/RegisterN';
// import signUp from './Components/RegisterNew';
const signUp = lazy(()=> import('./Components/RegisterNew'))
// import ServerError from './Components/ServerError';
// import CallingUrl from './Components/CallingUrl';
// import PricingPlans from './Components/PricingPlans';
const PricingPlans = lazy(()=> import('./Components/PricingPlans'))
// import SharedFileUrl from './Components/SharedFileUrl';
const SharedFileUrl = lazy(()=> import('./Components/SharedFileUrl'))
// import SignInWithOtp from './Components/SignInWithOtp';
const SignInWithOtp = lazy(()=> import('./Components/SignInWithOtp'))

// import TaskManagement from './Components/TaskManagement';
const TaskManagement = lazy(()=> import('./Components/TaskManagement'))
// import TaskManagementPanel from './Components/taskManagement/TaskManagementPanel';
const TaskManagementPanel = lazy(()=> import('./Components/taskManagement/TaskManagementPanel'))
// import Createnewtask from './Components/Createnewtask'
const Createnewtask = lazy(()=> import('./Components/Createnewtask'))



export default function App() {
  

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  		
  	useEffect(() => {
    	function onlineHandler() {
          localStorage.setItem("offline_count", '0');
      		setIsOnline(true);
    	}
	
    	function offlineHandler() {
      		setIsOnline(false);
    	}
    	function resizeMethod(e) {
        let width = e.target.outerWidth;
        let height = e.target.outerHeight;
        console.log(87, width,height)
        if(width < 1300 || height < 800){
          window.resizeTo(1300, 800)
        }else{
          window.resizeTo(width, height)
        }
        
    	}
	
    	window.addEventListener("online", onlineHandler);
    	window.addEventListener("offline", offlineHandler);
      window.addEventListener('resize', resizeMethod)

	
    	return () => {
      		window.removeEventListener("online", onlineHandler);
      		window.removeEventListener("offline", offlineHandler);
      		window.removeEventListener("resize", offlineHandler);
    	};
  	}, []);

  useEffect(() => {
    // if(window.location.href.indexOf('localhost') === -1 && window.location.href.indexOf('cadevcdn') === -1){
    //   window.oncontextmenu = function () {
    //     return false;
    //   }
    //   window.addEventListener('keydown', (event)=>{
    //     if (event.keyCode == 123) {
    //         event.preventDefault();
    //         console.log(47,event.keyCode)
    //         return false;
    //       }
    //       else if ((event.ctrlKey && event.shiftKey && event.keyCode == 73) || (event.ctrlKey && event.shiftKey && event.keyCode == 74) || (event.ctrlKey && event.shiftKey && event.keyCode == 67) || (event.ctrlKey  && event.keyCode == 85)) {
    //         event.preventDefault();
    //           return false;
    //       }
    //   });
    // }
    localStorage.removeItem('filter_active_for');
    localStorage.removeItem('popupFilter');

}, []);

  // return(
  //   console.log(32,'APPJS');
    return (
      <>
         <Suspense fallback={<div className="loaderMain" >
         <p>Please wait as this might take some time.</p><br/>
          <p style={{"marginTop": "105px"}}>Clearing old browser cache and reloading user data.</p>
         </div>}>
      <ReactPWAInstallProvider enableLogging>
        <Provider store={store}>
          <Router>
              <Switch>
                <PrivateRoute path="/logout" component={Logout} exact />
                {/* <PrivateRoute path="/call" component={Calling_window} exact /> */}
                <PrivateRoute path="/call/:cid" component={Calling_meeting} exact />
                <PublicRoute path="/register" component={Register} exact />
                <PublicRoute path="/signUp" component={signUp} exact />
                <PublicRoute path="/signup-starter" component={signUp} exact />
                <PublicRoute path="/signup-essential" component={signUp} exact />
                <PublicRoute path="/teammate/:id/:conversation_id" component={signUp} exact />
                <PublicRoute path="/teammate/:id" component={signUp} exact />
                <PublicRoute path="/login" component={Login} exact />
                <PublicRoute path="/company" component={Company} exact />
                <PublicRoute path="/forgot-password" component={ForgotPassword} exact />
                <PublicRoute path="/signin-with-otp/:email/:conversation_id" component={SignInWithOtp} exact />
                <PublicRoute path="/signin-with-otp/:email" component={SignInWithOtp} exact />
                <PublicRoute path="/signin-with-otp" component={SignInWithOtp} exact />
                <PublicRoute path="/invited_member/:email" component={InvitedMember} exact />
                <PrivateRoute path="/connect/notification" component={Connect} exact />
                <PrivateRoute path="/connect/adminSetting" component={Connect} exact />
                <PrivateRoute path="/connect/taskpanel" component={Connect} exact />
                <PrivateRoute path="/taskManagementPanel" component={TaskManagementPanel} exact />
                <PrivateRoute path="/taskManagement" component={TaskManagement} exact />
                <PrivateRoute path="/create_task" component={Createnewtask} exact />
                <PrivateRoute path="/connect/flagpanel" component={Connect} exact />
                <PrivateRoute path="/connect/filepanel" component={Connect} exact />
                <PrivateRoute path="/connect/workfreeli_ai" component={Connect} exact />
                  <PrivateRoute path="/connect/workfreelichat" component={Connect} exact />
                <PrivateRoute path="/adminpanel" component={AdminPanel} exact />
                <PrivateRoute path="/connect/:id" component={Connect} exact />
                <PrivateRoute path="/connect/newchat" component={Connect} exact />
                {/* <PrivateRoute path="/mobile" component={Mobile} exact /> */}
                {/* <PrivateRoute path="/mobile/chatscreen" component={ChatScreen} exact /> */}

                {/* <PublicRoute path="/files" component={Files}/> */}

                {/* <PrivateRoute path="/calendar" component={CalendarComponent} exact /> */}
                <PrivateRoute path="/connect" component={Connect} exact />
                <PrivateRoute path="/pricing-plan" component={PricingPlans} exact />
                {/* <Route path="/server-error" component={ServerError} exact /> */}
                {/* <Route path="/server-error" component={ErrorPage} exact /> */}
                <Route path="/c/:id" component={Calling_meeting} exact />
                <Route path="/u/:id" component={Calling_meeting} exact />
                <Route path="/f/:id" component={SharedFileUrl} exact />
                <Route path="/file" component={SharedFileUrl} exact />
                <PrivateRoute  path="/404" component={NotFound} exact />
                <PrivateRoute  path="/workfreeli-update" component={ErrorPage} exact />
                <PrivateRoute path="/" component={Connect} exact />
                {/* <PublicRoute  component={NotFound} exact /> */}
                <PublicRoute  component={Login} exact />
              

              </Switch>
          </Router>
          {/* {!isOnline ?<span  className="no_internet">You're offline. Check your connection.</span>:'' } */}
        </Provider>
      </ReactPWAInstallProvider>
      </Suspense>
      </>
    );
}


const initialState = {
    popup: {
        profilenav: false,
        no_internet: false,
        emojiContainer: false,
        fileuploadpopup: false,
        voiceuploadpopup: false,
        replyThread: false,
        participants_popup: false,
        tagPopup: false,
        tagPopupVoice: false,
        tagPopupAdmin: false,
        headmorepop: false,
        convFilter: false,
        threadConvFilter: false,
        galleryPopup: false,
        ClearMsgPopup: false,
        mute_popup: false,
        mute_all_popup: false,
        deleteRoom: false,
        deleteFile: false,
        leaveRoom: false,
        img_gallery: false,
        roomUpdate: false,
        userUpdate: false,
        thread_view: false,
        // thread_view:false,
        flagged_view: false,
        link_view: false,
        private_view: false,
        tagged_view: false,
        userMiniPopup: false,
        dirMsgPopup: false,
        createRoom: false,
        guestMembers: false,
        addMembers: false,
        Password: false,
        changePasswordAdmin: false,
        allRooms: false,
        topFloatBar: false,
        threadChat: false,
        searchChat: false,
        chatScreenMoreOpt: false,
        floatingAction: false,
        floatingActionTask: false,
        shareLinkPopup: false,
        playerPopup: false,
        attachMore: false,
        emojiPicker: false,
        uploadPopup: false,
        uploadPopupisOpen: false,
        mutePopup: false,
        // galleryPopup: false,
        // leaveRoom: false,
        clearMsg: false,
        // deleteRoom: false,
        selectMoreOpt: false,
        forwardPopup: false,
        leftConvFilterPopup: false,
        galleryTagFilter: false,
        imageViewer: false,
        chooseCreateConv: false,
        createGroupConv: false,
        taskCreator: false,
        callParticipantPopup: false,
        deleteMsgPopup: false,
        assignPopup: false,
        downloadingPopup: false,
        warningPopup: false,
        VersionUpdatePopup: false,
        notificationPopup: false,
        customTitle: false,
        comingSoon: false,
        editChecklist: false,
        convSearchSec: false,
        multiAccount: false,
        voipHoldMute: false,
        autoplayConfirm: false,
        mergeconvpopup: false,
        upgradePlan: false,
        CallingInfoMsg: false,
        xmpp_fire: false,
        xmpp_online: false,
        createUser: false,
        createNewUser: false,
        companyUpdate: false,
        closeTeamModal: false,
        updateTeam: false,
        globalEmptyPopup: false,
        createTag: false,
        editTag: false,
        archive: false,
        quick_view: false,
        guest_call_join: false,
        user_call_join: false,
        drag_n_drop: true,
        xmpp_no_connection: false,
        voice_msg_popup: false,
        companyDetails: false,
        logoutpop: false,
        devicechange: false,
        colorPlate: ['#732be2','#078cfe','#ff9988', '#7ed6d0', '#8b57db', '#2c56ac', '#a6d068', '#69abf9', '#ffaf4c'],
        StartDate: "",
        EndDate: "",
        ReportIndex: 0,
        WorkloadType: '',
        reportSlider: false,
        dataChange: false,
        // NotSetForReviewed:0,
        fromReview: false,
        selectedObs: [],
        OkTask: [],
        TaskInsightData: false,
        ReloadNeed: false,
        TaskStatus: '',
        runreport: false,
        incomplete: false,
        fromWorkLoad: "",
        msg_is_sent: false,
        backToRoom: false,
        priority_col: false,
        startdate_col: false,
        assignedTo_col: false,
        createdBy_col: false,
        reviewPending_col: false,
        observer_col: false,
        keywords_col: false,
        cost_col: false,
        hours_col: false,
        total: 0,
        adminSettings_view: false,
        leftBarFilterPopup: false,
        kanbanFilterPopup: false,
        tagSideBar: false,
        taskDashboardPopup: false,
        quickTaskPopup: false,
        createProgress: false,
        editProgress: false,

        // task popups,
        keywordListPopup: false,
        itemOpen: false,
        sweetAlert: false,
        viewAddAssign: false,
        taskChecklistToggle: false,
        taskFileuploadToggle: false,
        noteCollapse: false,
        desCollapse: false,
        trackCostsPopUp: false,
        trackHoursPopUp: false,
        observersListPopup: false,
        roomListPopup: false,
        isEditTitle: false,
        roomEdit: false,
        taskOwnerPopup: false,
        itemOpen2: false,
        isEditProjectTitle: false,
        taskProjectListPopup: false,
        toggleKeyword: false,
        addAssign: false,
        addObs: false,
        roomChangePopup: false,
        selectPopupRestore: false,
        selectPopup: false,
        PopKanbanOption: false,
        relatedKeywordPopup: false,
        filterKeywordPopup: false,
        relatedAssigneePopup: false,
        dateRangePopup: false,
        taskObserverRelatedPopup: false,
        taskRoomsRelatedPopup: false,
        taskprojectRelatedPopup: false,
        projectlists: false,
        newchat_view: false,
        projectEdit: false,
        projectDelete: false,
        reportPopup: false,
        reportListPopup: false,
        reportvenuesPopup:false,
        reportdatePopup:false,
        venueList:false,
        dateTopFilter:false,
        venueTopFilter:false,
        reportCreateVenue: false,
        reportparticipants_popup: false,
        reportRecipants_popup: false,
        maintenancePopup: false,
        createaTicketPopup: false,
        maintenanceFileUploadPopup: false,
        maintenanceSubmitterPopup: false,
        maintenancePaticipantPopup: false,
        maintenancevenuesPopup: false,
        maintenancedatePopup: false,
        maintenanceLeftbarVenues: false,
        maintenanceChatbox: false,
        showPopupMaintanenace:false

    },
    globalEmpty: {
        empty_message: '',
        empty_header: ''
    },

    popup_action_data: {},
    conversation_gallery: false,
    popup_action_data_guest: {},
    quick_view_data: {},
    callringpopup: { status: false },
    callstatuspopup: { status: false },
    callratingpopup: { status: false },
    urlresetpopup: { status: false },
    busymsgpopup: false,
    filter_active_for: '',
    msgBoxHeight: '',
    imageViewerSrc: '',
    active_img: {},
    unread_thread_conv: [],
    active_gallery_tab: 'all',
    fileViewType: '',
    active_gallery_search: '',
    selected_gallery_files: [],
    selected_files_data: [],
    active_theme: 'light',
    multi_file_ids: [],
    popup_video: '',
    downloading_file: [],
    downloading_progress: {},
    popup_action_data_checklist: {},
    go_with_noti: null,
    noti_click_count: 0,
    setSearchVal: '',
    updateVersion_data: 'no',
    app_ts: '0',
    RepemojiView: false,
    Task_RepemojiView: false,
    Emojiupdate: true,
    // WorkloadType:'this_week',
    StartDate: "",
    EndDate: "",
    Task_Emojiupdate: true,
    clearText: false,
    create_task: false,
    taskTab: 'insights',
    taskRoom: null,
    projectRoom: {
            id: "all", projectTitle: "All tasks"
        },
    taskLoads: false,
    reportSlider: false,
    view_type: '',
    OutlookId: '',
    GoogleId: '',
    popup_report_data: {},
    popup_ticket_data: {},
    popup_venue_data: {},
    selected_venue: null,
    selected_venue_id: null,
    maintenance_venue: null,
    maintenance_venue_id: null,
    maintenance_form: ""
}

function setPopup(state, action) {
    if (action.payload.type === 'flagged_view' || action.payload.type === 'archive' || action.payload.type === 'private_view' || action.payload.type === 'link_view' || action.payload.type === 'thread_view') {
        localStorage.setItem('popupFilter', action.payload.data)
        if (action.payload.data) {
            localStorage.setItem('popupFilterType', action.payload.type)
        } else {
            localStorage.setItem('popupFilterType', '')
        }

    } else {
    }

    return {
        ...state,
        popup: {
            ...state.popup,
            [action.payload.type]: action.payload.data,
            drag_n_drop: !action.payload.data ? true : action.payload.type === 'fileuploadpopup' || action.payload.type === 'xmpp_fire' || action.payload.type === 'xmpp_online' ? true : false
        }
    }
}

function popupReducer(state = initialState, action) {

    switch (action.type) {
        case 'setPopup':
            return setPopup(state, action);

        case 'quickViewChange':
            return {
                ...state,
                quick_view_data: action.payload.msg_id === state.quick_view_data.msg_id ? { ...state.quick_view_data, [action.payload.field]: action.payload[action.payload.field] } : state.quick_view_data
            }

        case 'set_popup_action_data':
            return {
                ...state,
                popup_action_data: action.payload
            }

        case 'set_popup_action_data_guest':
            return {
                ...state,
                popup_action_data_guest: action.payload
            }
        case "set_popup_report_data":
            return {
                ...state,
                popup_report_data: action.payload,
            };
        case "set_popup_ticket_data":
            return {
                ...state,
                popup_ticket_data: action.payload,
            };
        case "set_popup_venue_data":
            return {
                ...state,
                popup_venue_data: action.payload,
            };
        case "set_selected_venue" :
            return {
                ...state,
                selected_venue: action.payload,
            }
        case "set_selected_venue_id" :
            return {
                ...state,
                selected_venue_id: action.payload,
            }
        case 'set_maintenance_venue' :
            return {
                ...state,
                maintenance_venue: action.payload,
            }
        case 'set_maintenance_venue_id' :
            return {
                ...state,
                maintenance_venue_id : action.payload
            }
        case 'set_maintenance_form':

            return {
                ...state,
                maintenance_form: action.payload,
            }
        case 'set_quick_view_data':
            return {
                ...state,
                quick_view_data: action.payload
            }

        case 'set_popup_action_data_checklist':
            return {
                ...state,
                popup_action_data_checklist: action.payload
            }

        case 'set_multi_file_ids':
            return {
                ...state,
                multi_file_ids: action.payload
            }

        case 'set_selected_gallery_files':
            return {
                ...state,
                selected_gallery_files: action.payload
            }

        case 'set_selected_files_data':
            return {
                ...state,
                selected_files_data: action.payload
            }

        case 'set_active_gallery_tab':
            return {
                ...state,
                active_gallery_tab: action.payload
            }
        case 'set_taskMangerPopups':
            return {
                ...state,
                taskMangerPopups: action.payload
            }
        case 'set_fileViewType':
            return {
                ...state,
                fileViewType: action.payload
            }

        case 'set_conversation_gallery':
            return {
                ...state,
                conversation_gallery: action.payload
            }
        case 'set_create_task':
            return {
                ...state,
                create_task: action.payload
            }

        case 'set_set_active_gallery_search':
            return {
                ...state,
                active_gallery_search: action.payload
            }

        case 'set_searchVal':
            return {
                ...state,
                setSearchVal: action.payload
            }

        case 'set_active_theme':
            return {
                ...state,
                active_theme: action.payload
            }
        case 'set_adminSettings_view':
            return {
                ...state,
                adminSettings_view: action.payload
            }
        case 'set_callringpopup':

            console.log('set_callringpopup', action)
            return {
                ...state,
                callringpopup: action.payload
            }

        case 'set_urlresetpopup':

            console.log('set_urlresetpopup', action)
            return {
                ...state,
                urlresetpopup: action.payload
            }

        case 'set_call_status_popup':

            console.log('set_callstatus_popup', action)
            return {
                ...state,
                callstatuspopup: action.payload
            }


        case 'set_call_rating_popup':
            return {
                ...state,
                callratingpopup: action.payload
            }


        case 'set_activeImg':
            return {
                ...state,
                active_img: action.payload
            }

        case 'set_filter_active_for':
            localStorage.setItem('filter_active_for', action.payload)
            return {
                ...state,
                filter_active_for: action.payload,
                go_with_noti: null,
                noti_click_count: 0,
            }


        case 'setImageViewerSrc':
            return {
                ...state,
                imageViewerSrc: action.payload
            }

        case 'set_msgBoxHeight':
            return {
                ...state,
                msgBoxHeight: action.payload
            }

        case 'downloadingPopup':
            return {
                ...state,
                downloadingPopup: action.payload
            }

        case 'warningPopup':
            return {
                ...state,
                warningPopup: action.payload
            }


        case 'set_popup_video':
            return {
                ...state,
                popup_video: action.payload
            }

        case 'set_downloading_file':
            return {
                ...state,
                downloading_file: action.payload
            }
        case 'set_downloading_progress':
            return {
                ...state,
                downloading_progress: action.payload
            }
        case 'convSearchSec':
            return {
                ...state,
                convSearchSec: action.payload
            }

        case 'multiAccount':
            return {
                ...state,
                convSearchSec: action.payload
            }

        case 'set_go_with_noti':
            return {
                ...state,
                go_with_noti: action.payload
            }

        case 'set_noti_click_count':
            return {
                ...state,
                noti_click_count: action.payload
            }

        case 'setGlobalEmpty':
            return {
                ...state,
                globalEmpty: action.payload
            }

        case 'updateVersion':
            return {
                ...state,
                updateVersion_data: action.payload
            }

        case 'updateVersionNum':
            return {
                ...state,
                app_ts: action.payload
            }

        case 'RESET_APP':
            return initialState

        case 'busy_call_msg':
            return {
                ...state,
                busymsgpopup: action.payload
            }

        case 'set_EmojiView':
            return {
                ...state,
                RepemojiView: action.payload
            }


        case 'set_Task_EmojiView':
            return {
                ...state,
                Task_RepemojiView: action.payload
            }

        case 'set_clear_text_icon':
            return {
                ...state,
                clearText: action.payload
            }


        case 'set_Update':
            return {
                ...state,
                Emojiupdate: action.payload
            }

        case 'set_Task_emojiUpdate':
            return {
                ...state,
                Task_Emojiupdate: action.payload
            }

        case 'set_workload_type':
            return {
                ...state,
                WorkloadType: action.payload
            }
        case 'set_workload_start_date':
            return {
                ...state,
                StartDate: action.payload
            }
        case 'set_workload_end_date':
            return {
                ...state,
                EndDate: action.payload
            }
        case 'setTaskLoads':
            return {
                ...state,
                taskLoads: action.payload
            }
        case 'setTaskTab':
            return {
                ...state,
                taskTab: action.payload
            }
        case 'setTaskRoom':
            return {
                ...state,
                taskRoom: action.payload
            }
        case 'setProjectRoom':
            return {
                ...state,
                projectRoom: action.payload
            }
        case 'setViewType':
            return {
                ...state,
                view_type: action.payload
            }
        case 'setGoogleId':
            return {
                ...state,
                GoogleId: action.payload
            }
        case 'setOutlookId':
            return {
                ...state,
                OutlookId: action.payload
            }    



        default: {
            return {
                ...state
            }
        }
    }
}

export default popupReducer;

const handleError = (error,reject)=>{
    if(!navigator.onLine){
        let count =  localStorage.getItem("offline_count");
        if(count){
            localStorage.setItem("offline_count", Number(count) +1);
        }else{
            localStorage.setItem("offline_count", 1);
        }
       
    }
    if(error.response){
        console.log(4,error.response)
        if(error.response?.data?.body?.error === 'Device is not listed. Please Login.'){
            localStorage.clear();
            window.location.href = '/login'
        }
        else if(error.response.status === 401){
            reject({type:'error',data:error.response.data})
            // window.location.href = '/logout';
        }else if(error.response.status.toString().indexOf('5') > -1){
            console.log(6,error.response)
            // window.location.href = '/server-error';
        }else{
            reject({type:'error',data:error.response.data})
        }

    }else if(error?.request?.status === 0){
        console.log(12,error.response)
        console.log(14,error.response)
        // window.location.href = '/server-error';
    }else{
        reject({type:'error',data:error.data});
    }
}

export default handleError